import React from "react";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);
function TestimonialArea() {
  const testimonialSlider = {
    slidesPerView: 1,
    loop: true,
    speed: 2000,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };
  return (
    <>
      <section className="testimonial-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="swiper testimonial-slider">
                <Swiper
                  pagination={{
                    type: "fraction",
                  }}
                  {...testimonialSlider}
                  className="swiper-wrapper"
                >
                  <SwiperSlide className="swiper-slide">
                    <div className="testimonial-content">
                      <div className="quote">
                        <i className="fas fa-quote-left" />
                      </div>
                      <div className="client-info">
                        <div className="client-pic">
                          <img
                            src={process.env.PUBLIC_URL + "/images/client.jpg"}
                            alt="images"
                          />
                        </div>
                        <div className="client-details">
                          <h4>Savannah Nguyen</h4>
                          <span>Executive CEO</span>
                        </div>
                      </div>
                      <p>
                        <i className="fas fa-quote-left" /> Our collaboration with Netfusion Technologies has been nothing short of extraordinary. From the moment we engaged with them, their dedication to understanding our vision and translating it into a tangible, visually stunning reality was evident. The level of professionalism they maintained throughout the entire process, coupled with their unwavering commitment to delivering a product that not only met but exceeded our expectations, truly sets them apart.
                        <i className="fas fa-quote-right" />
                      </p>
                      <div className="rating">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="testimonial-content">
                      <div className="quote">
                        <i className="fas fa-quote-left" />
                      </div>
                      <div className="client-info">
                        <div className="client-pic">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/client-2.jpg"
                            }
                            alt="images"
                          />
                        </div>
                        <div className="client-details">
                          <h4>Nailong Jeso</h4>
                          <span>CTO Founder</span>
                        </div>
                      </div>
                      <p>
                        <i className="fas fa-quote-left" /> It's rare to find a team that combines technical expertise with an artistic touch, but that's exactly what we discovered in our collaboration with this company. From the initial brainstorming sessions to the final implementation, they showcased an impressive blend of creativity and precision. 
                        <i className="fas fa-quote-right" />
                      </p>
                      <div className="rating">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="testimonial-content">
                      <div className="quote">
                        <i className="fas fa-quote-left" />
                      </div>
                      <div className="client-info">
                        <div className="client-pic">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/client-3.jpg"
                            }
                            alt="images"
                          />
                        </div>
                        <div className="client-details">
                          <h4>Gautam Yamni</h4>
                          <span>Designer Head</span>
                        </div>
                      </div>
                      <p>
                        <i className="fas fa-quote-left" /> Choosing a partner for our project was a crucial decision, and we're delighted that we selected this team. Their approach goes beyond merely delivering a service; they become invested in the success of your project. Throughout the entire process, communication was transparent, deadlines were met with precision, and the level of professionalism exhibited was exceptional. 
                        <i className="fas fa-quote-right" />
                      </p>
                      <div className="rating">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className="swiper-button-next" />
                <div className="swiper-button-prev" />
                <div className="swiper-pagination" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TestimonialArea;
