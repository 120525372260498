const data = [
    {
        id:1,
        title:"UI Kit",
        heading:"E-Shop Ecommerce",
        image:"images/portfolio-1.jpg",
        category: "UI/UX",
    },
    {
        id:2,
        title:"Software",
        heading:"Desktop Mockup",
        image:"images/portfolio-2.jpg",
        category: "Web Design",
    },
    {
        id:3,
        title:"Graphic",
        heading:"Art Deco Cocktails",
        image:"images/portfolio-3.jpg",
        category: "Developing",
    },
    {
        id:4,
        title:"UI & UX",
        heading:"Design Demo",
        image:"images/portfolio-4.jpg",
        category: "Graphic Design",
    },
    {
        id:5,
        title:"Video",
        heading:"Animation Studio",
        image:"images/portfolio-5.jpg",
        category: "Developing",
    },
    {
        id:6,
        title:"UI Kit",
        heading:"Creative Agency",
        image:"images/portfolio-6.jpg",
        category: "Web Design",
    },
    {
        id:7,
        title:"Motion",
        heading:"Motion Graphics",
        image:"images/portfolio-7.jpg",
        category: "UI/UX",
    },
    {
        id:8,
        title:"App",
        heading:"Mobile Crypto Wallet",
        image:"images/portfolio-8.jpg",
        category: "Graphic Design",
    },
    {
        id:9,
        title:"Android",
        heading:"Android For Mobile",
        image:"images/portfolio-5.jpg",
        category: "Graphic Design",
    },
    {
        id:10,
        title:"Android",
        heading:"Android For Mobile",
        image:"images/portfolio-7.jpg",
        category: "Graphic Design",
    },
    {
        id:11,
        title:"Android",
        heading:"Android For Mobile",
        image:"images/portfolio-9.jpg",
        category: "Graphic Design",
    },
    {
        id:11,
        title:"Android",
        heading:"Android For Mobile",
        image:"images/portfolio-8.jpg",
        category: "Developing",
    },
];

export default data;
