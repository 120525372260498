import React from "react";

function ProjectProcess() {
  return (
    <>
      <div className="project-process">
        <div className="row justify-content-between">
          <div className="col">
            <div className="process-step">
              <h4>Client:</h4>
              <span>Exportius</span>
            </div>
          </div>
          <div className="col">
            <div className="process-step">
              <h4>Services:</h4>
              <span>UI/UX Design,Developing</span>
            </div>
          </div>
          <div className="col">
            <div className="process-step">
              <h4>Website:</h4>
              <span>www.xportius.com</span>
            </div>
          </div>
          <div className="col">
            <div className="process-step">
              <h4>Start Date:</h4>
              <span>09.03.2023</span>
            </div>
          </div>
          <div className="col">
            <div className="process-step">
              <h4>end Date:</h4>
              <span>01.05.2023</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="process-banner">
              <img
                src={process.env.PUBLIC_URL + "/images/process-banner.jpg"}
                alt="images"
              />
            </div>
          </div>
        </div>
        <div className="project-overview">
          <h3>OVERVIEW</h3>
          <div className="row">
            <div className="col-md-5 col-lg-5 col-xl-5">
              <div className="overview-img">
                <img
                  src={process.env.PUBLIC_URL + "/images/overview-1.jpg"}
                  alt="images"
                />
              </div>
            </div>
            <div className="col-md-7 col-lg-7 col-xl-7">
              <div className="overview-content">
              <h3>Unleashing Exportius's Digital Odyssey</h3>
                <p>

Embarking on Exportius's digital journey was a vibrant exploration. In the realm of dynamic experiences, Exportius discovered its digital heartbeat. Creativity danced with user-centric design, weaving a symphony of experiences. Each click echoed a story, and every scroll narrated a chapter. Ideas painted the canvas of the future, and innovation brushed strokes of progress.

Navigating technological currents, Exportius aimed its arrow at the bullseye of seamless functionality. The spice of creativity flavored the interface, turning each interaction into a delightful taste. The project's pulse beat in harmony, opening doors to a world of possibilities. The heartbeat of progress guided the journey to a destination where success met purpose.

Rising like the sun on a new digital horizon, Exportius carved the path forward. Versatility met elegance, and wisdom infused every line of code. The fortress of resilience stood strong, embracing challenges with open arms. The ache of growth mingled with the joy of achievement. At the pinnacle, a roar of triumph echoed through Exportius's digital evolution.

A testament to commitment, excellence echoed in every line of code. Triumph roared, tools lined the digital arsenal, and design harmonized with functionality. Innovation remained the soul of Exportius's journey, marking a profound commitment to progress.
                       </p>
              </div>
            </div>
          </div>
        </div>
        <div className="project-overview right">
          <h3>PROJECT PROCESS</h3>
          <div className="row">
            <div className="col-md-6 col-lg-3 col-xl-3 or4">
              <div className="project-single-step">
                <h4>04. Developing</h4>
                <div className="project-step-img">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/project/project-single-step-4.jpg"
                    }
                    alt="images"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-xl-3 or3">
              <div className="project-single-step">
                <h4>03. UI Design</h4>
                <div className="project-step-img">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/project/project-single-step-3.jpg"
                    }
                    alt="images"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-xl-3 or2">
              <div className="project-single-step">
                <h4>02. Wireframe</h4>
                <div className="project-step-img">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/project/project-single-step-2.jpg"
                    }
                    alt="images"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-xl-3 or1">
              <div className="project-single-step">
                <h4>01. Brainstorming</h4>
                <div className="project-step-img">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/project/project-single-step-1.jpg"
                    }
                    alt="images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="project-overview">
          <h3>Check &amp; launch</h3>
          <div className="row">
            <div className="col-md-7 col-lg-7 col-xl-7 or2">
              <div className="overview-content">
                <p>
                In the exciting journey of Exportius's digital evolution, the "Check & Launch" stage was like the big moment before a grand show. This part was about making sure everything was perfect and ready to go.

Just like double-checking your work before handing it in, the team carefully looked at every detail to make sure there were no mistakes. It was like making sure all the instruments in a band were playing the right notes.

When it was time to launch, it felt a bit like a spaceship taking off. Each part had a role, like teamwork in a sports game. The team was confident and ready, knowing they'd done their best to create something awesome.
                </p>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xl-5 or1">
              <div className="overview-img">
                <img
                  src={process.env.PUBLIC_URL + "/images/overview-2.jpg"}
                  alt="images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectProcess;
